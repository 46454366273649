document.addEventListener('DOMContentLoaded', function() {

    // Input password eye function.
    document.querySelectorAll('.input-group-to-text').forEach(function (eyeEl) {
        eyeEl.addEventListener('click', function() {
            const passwordField = this.closest('.input-group').querySelector('.form-control-password');
            const passwordEye = this.querySelector('.pass-eye');
            
            if (passwordField.type === 'password') {
                passwordField.type = 'text';
                if (passwordEye) passwordEye.classList.add('show');
            } else {
                passwordField.type = 'password';
                if (passwordEye) passwordEye.classList.remove('show');
            }
        });
    });


    // Equipment circle cursor function.
    document.querySelectorAll('.cursor-circle').forEach(function (cursorEl) {
        const circle = cursorEl;
        const circleStyle = circle.style;

        document.addEventListener('mousemove', e => {
            window.requestAnimationFrame(() => {
                circleStyle.top = `${e.clientY - circle.offsetHeight / 2 + 35}px`;
                circleStyle.left = `${e.clientX - circle.offsetWidth / 2 + 35}px`;
            });
        });
    });


    // Equipment content duplicate in equipment modal window. 
    document.querySelectorAll('.equipment-item').forEach(function(equipmentEl) {
        equipmentEl.addEventListener('click', function () {
            
            const modalProductText = document.getElementById('ModalProductText');
            const productTitle = equipmentEl.querySelector('h1').cloneNode(true);
            const productDescription = equipmentEl.querySelector('p').cloneNode(true);
            
            modalProductText.innerHTML = '';
            
            modalProductText.appendChild(productTitle);
            modalProductText.appendChild(productDescription);
            
            const productImageSrc = equipmentEl.querySelector('.equipment-item__link-image').getAttribute('src');
            document.getElementById('ModalProductImage').setAttribute('src', productImageSrc);
            
            const modalBtnGroup = document.querySelector('.btn-group-modal');
            const listItems = equipmentEl.querySelectorAll('.btn-group .dropdown-menu li a');
            
            modalBtnGroup.innerHTML = '';
            
            listItems.forEach(function(aTag) {
                const clonedLink = aTag.cloneNode(true);
                modalBtnGroup.appendChild(clonedLink);
            });
        });
    });
    
    // Inputs edit - changhe elements.
    document.querySelectorAll('.step-block__content-item').forEach(function(stepBlockContentItem) {
        const stepBlockContentItemCheckbox = stepBlockContentItem.querySelector('.input-group-text>.form-check-input');
        const stepBlockContentItemTextInput = stepBlockContentItem.querySelector('.form-control');
        const stepBlockContentItemTextValue = stepBlockContentItem.querySelector('.form-control-value');
        const stepBlockContentItemCheckboxInput = stepBlockContentItem.querySelector('.form-switch>.form-check-input');
        const stepBlockContentItemCheckboxLabel = stepBlockContentItem.querySelector('.form-switch>.form-check-label');

        if (stepBlockContentItemCheckbox && stepBlockContentItemTextInput) {
            stepBlockContentItemCheckbox.addEventListener('change', function() {
                if (stepBlockContentItemCheckbox.checked) {
                    stepBlockContentItemTextInput.removeAttribute('disabled');
                } else {
                    stepBlockContentItemTextInput.setAttribute('disabled', true);
                }
            });
        }

        if (stepBlockContentItemTextValue) {
            stepBlockContentItemTextValue.innerHTML = stepBlockContentItemTextInput.value + '&nbsp;';

            stepBlockContentItemTextInput.addEventListener('input', function() {
                stepBlockContentItemTextValue.innerHTML = stepBlockContentItemTextInput.value + '&nbsp;';
            });
        }

        if (stepBlockContentItemCheckboxInput && stepBlockContentItemCheckboxLabel) {
            if (stepBlockContentItemCheckboxInput.checked) {
                stepBlockContentItemCheckboxLabel.innerHTML = 'Yes';
            } else {
                stepBlockContentItemCheckboxLabel.innerHTML = 'No';
            }

            stepBlockContentItemCheckboxInput.addEventListener('change', function () {
                if (stepBlockContentItemCheckboxInput.checked) {
                    stepBlockContentItemCheckboxInput.value = 'Yes';
                    stepBlockContentItemCheckboxLabel.innerHTML = 'Yes';
                } else {
                    stepBlockContentItemCheckboxInput.value = 'No';
                    stepBlockContentItemCheckboxLabel.innerHTML = stepBlockContentItemCheckboxInput.value;
                }
            });
        }
    });

    
    // Input disabling by checkbox.
    document.querySelectorAll('.step-block').forEach(function (stepBlock) { 
        const buttonEdit = stepBlock.querySelector('.btn-edit');
        const inputFormControls = stepBlock.querySelectorAll('.form-control, .form-select');

        function disabledStepBlock() {
            inputFormControls.forEach(function (inputFormControl) { 
                if (stepBlock.classList.contains('disabled')) {
                    inputFormControl.setAttribute('disabled', 'true');
                } else {
                    inputFormControl.removeAttribute('disabled');
                }
            });
        }

        if (buttonEdit) {
            buttonEdit.addEventListener('click', function () {
                stepBlock.classList.toggle('disabled');

                disabledStepBlock();
            }); 

            // disabledStepBlock();
        }
    });

    // Table striped.

    const tbodyRows = document.querySelectorAll('tbody tr');
    let isOdd = false;

    tbodyRows.forEach((tbodyRow) => {
        if (tbodyRow.id.startsWith('category-')) {
            isOdd = false;
        } else {
            if (isOdd) {
                tbodyRow.classList.add('odd-row');
            }
            isOdd = !isOdd;
        }
    });



    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });
    
});